<template>
  <div id="app" data-server-rendered='true'>
    <!-- <router-view> </router-view>- -->
    <layout></layout>
  </div>
</template>

<script>

import { scrollTo } from '@/utils/scroll-to'
import layout from './views/layout.vue';
export default {
  name: "App",
  components: {
    layout,
  },

  created() {
    this.$store.dispatch("GETHOMEDATA").then(res => {
      // console.log(res)
    })

  },
  mounted() {

  }


};
</script>
<style>
@media (max-width:1441px) {
  ._headerAuto {
    padding: 0 20px;
  }
  ._auto {
    width: 100% !important;
    padding: 0 20px;
  }
}
._impact {
  font-family: Impact;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-weight: normal; */
  /* font-size: 16px; */



}
._fontNum {
  font-family: 'Impact';
}
#app {
  /* background-color: #f5f5f5; */
  /* overflow: hidden; */
}
body {
  background-color: #f5f5f5;
}
body::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(144, 147, 153, .3);
}
</style>
