let BASE_API,IMG_URL
if (process.env.NODE_ENV === "development") {

    /**
     * 开发环境
     */

	// BASE_API = "https://www.zglyzjng.com/api/v1"
	BASE_API = "https://www.zglyzdsdjng.cn/api/v1"


    IMG_URL = "https://www.zglyzdsdjng.cn/"

	
} else {


    /**
     * 生产环境
     */
    BASE_API = "https://www.zglyzdsdjng.cn/api/v1" //生产环境api
    IMG_URL = "https://www.zglyzdsdjng.cn/"
    

}
export { BASE_API,IMG_URL}