<template>
    <div class="_icon" v-show="show">


        <div>
            <el-popover placement="left" width="200" trigger="hover" v-if="seen">
                <div class="_code">
                    <img :src="$IMG_URL + $store.state.homeData.web_data.dy_qrcode" alt="">
                </div>
                <img class="_ic" slot="reference" src="@/assets/images/dy.png" alt="">
            </el-popover>
        </div>



        <div>
            <el-popover placement="left" width="200" trigger="hover" v-if="seen">
                <div class="_code">
                    <img :src="$IMG_URL + $store.state.homeData.web_data.oa_qrcode" alt="">
                </div>
                <img class="_ic" slot="reference" src="@/assets/images/wx-1.png" alt="">
            </el-popover>

        </div>


        <div>


            <img v-if="seen" class="_ic" @click="goTop" src="@/assets/images/fh.png" alt="">
            <img v-if="!seen" class="_ic1" @click="goTop" src="@/assets/images/phone/fh.png" alt="">


        </div>


    </div>
</template>

<script>
export default {
    data() {
        return {
            seen: true,
            show: false,
        }
    },
    mounted() {
        this.menuSeen()

        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            const distance = window.scrollY
            // console.log(distance)
            if (distance > 400) {
                this.show = true
            } else {
                this.show = false

            }
        },
        goTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            })
        },
        menuSeen() {
            // 屏幕尺寸
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 415) {
                this.seen = true;
            } else {
                this.seen = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._icon
{
    position: fixed;
    top: 60%;
    right: 10px;
    z-index: 999;
}


._code
{
    width: 160px;
    height: 160px;
    margin: 0 auto;
    // padding: 12px 0;
    // margin-left: -2px;

    img
    {
        width: 100%;
        height: 100%;
    }
}

._ic
{
    cursor: pointer;
}

._ic1
{
    width: 48px;
    height: 48px;

}

._icon
{
    @include respondTo('phone')
    {
        top: 80%;
    }
}
</style>