import Vue from 'vue'
const DISTANCE = 150

const DURATION = 1000
const animationMap = new Map()
const ob = new IntersectionObserver((entries) => {
    console.log(entries)
    for (const entry of entries) {
        if (entry.isIntersecting) {
            const animation = animationMap.get(entry.target)
            animation.play()
        }
    }

})

Vue.directive("sidein", (el) => {
    const animation = el.animate(
        [
            {
                transform: `translateY(${DISTANCE}px)`,
                opacity: 0.5,
            },
            {
                transform: `translateY(0)`,
                opacity: 1,
            }
        ], {
        duration: DURATION,
        easing: "ease"
    }
    )


    animation.pause()
    animationMap.set(el, animation)
    ob.observe(el)



})
