
export function scrollToSection(sectionId, num) {
    // console.log(num)
    const defaultNUm = 180
    let numTop = 0
    if (num) {
        numTop = num
    } else {
        numTop = defaultNUm
    }
    const container = document.querySelector(`#${sectionId}`)

    const top = container.getBoundingClientRect().top + window.scrollY - numTop

    // console.log(top)
    window.scrollTo({
        top: top,
        behavior: 'smooth' // 平滑滚动
    })

    
}