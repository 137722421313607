<template>
    <div class="header" :style="styleHeader">

        <transition name="el-fade-in-linear">
            <div class="_topMenu" v-show="showTop">
                <div class="_headerAuto">


                    <div class="_right">

                        <span @click="goOther(1)">意见反馈</span>
                        <span class="_xian"></span>
                        <span @click="goOther(2)">问卷调查</span>


                    </div>

                </div>
            </div>
        </transition>

        <!-- web端菜单导航 -->
        <div class="_boMenu">
            <div class="_headerMenu">
                <div id="menu_index">

                    <div class="logo_img">
                        <div @click="logoClick">
                            <div class="header-logo">
                                <img id="header-img" v-if="seen" src="@/assets/images/Logo.png">
                                <img id="header-img" v-if="!seen" src="@/assets/images/phone/Logo@2x.png">
                                
                            </div>
                        </div>

                    </div>
                    <el-menu v-if="seen" :default-active="defaultActive" @select="select" class="el-menu-demo"
                        menu-trigger="click" text-color="rgb(204, 204, 204)" active-text-color="#fff" mode="horizontal"
                        style="font-size: 20px;">
                        <el-menu-item v-for="(item, index) in menuList" :key="index" :index="item.id"
                            class="header-title">

                            <span class="_sp" @mouseenter="hoverItem(item, index)">{{ item.name }}</span>
                        </el-menu-item>

                    </el-menu>

                    <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->

                    <div class="_div" @click="openMenu" v-if="!seen">
                        <img :src="menuSrc" alt="">
                    </div>
                </div>

            </div>
            <div class="_hidden" v-show="showHidden" @mouseleave="hoverLeave">

                <div class="_hidden_cont" :style="styleObj">
                    <p v-for="(item, index) in subTitle" :key="index" @click="goTwo(item, index)">
                        <span :class="{ _span_active: title == item.subTitle }">{{ item.subTitle }}</span>
                        <img v-if="subTitle.length - 1 != index" src="@/assets/image/home-img/rhombus.png" alt="">
                    </p>


                </div>

            </div>
        </div>


        <menuDrawer ref="drawerRef" @changeDraw="changeDraw"></menuDrawer>

    </div>
</template>

<script>
import { listMenu } from './list';
import menuDrawer from './menuDrawer.vue';

export default {
    components: {
        menuDrawer
    },
    data() {
        return {
            menuSrc: require("../../assets/images/phone/menu.png"),
            defaultActive: "/",
            showTop: true,
            title: "",
            styleHeader: {},
            styleObj: {},
            listMenu: listMenu,
            showHidden: false,
            current: -1,
            menuList: [
                {
                    id: "/",
                    name: "首页"
                },
                {
                    id: "information",
                    name: "关于本馆"
                },
                {
                    id: "visit",
                    name: "陈列展览"
                }, {
                    id: "exhibit",
                    name: "公共服务"
                }, {
                    id: "takeCare",
                    name: "宣传教育"
                },
                {
                    id: "education",
                    name: "馆藏文物"
                }, {
                    id: "study",
                    name: "学术研究"
                }, {
                    id: "trail",
                    name: "方志馆"
                }
            ],
            activeIndex: "index",
            seen: true,
            currentMenu: "首页",
            subTitle: [
            ],

            flg: true,

        }
    },
    watch: {
        $route(to, from) {
            // console.log(to);
            let title = to.query.tit
            this.title = title

            let aaa = location.pathname == '/' ? location.pathname : location.pathname.substring(1)


            for (let i in this.listMenu) {
                for (let j in this.listMenu[i].child) {
                    if (aaa == this.listMenu[i].child[j].id) {

                        this.defaultActive = this.listMenu[i].id
                        console.log(this.listMenu[i].id)
                        return;
                    } else {
                        this.defaultActive = aaa
                    }
                }
            }

        }
    },
    created() {

        let aaa = location.pathname == '/' ? location.pathname : location.pathname.substring(1)
        let title = this.$route.query.tit

        for (let i in this.listMenu) {
            for (let j in this.listMenu[i].child) {
                if (aaa == this.listMenu[i].child[j].id) {

                    this.defaultActive = this.listMenu[i].id
                    console.log(this.listMenu[i].id)
                    return;
                } else {
                    this.defaultActive = aaa
                }
            }
        }
    },
    mounted() {
        this.menuSeen()

        if (this.seen) {
            window.addEventListener('scroll', this.handleScroll)

        }
    },
    methods: {
        select(index, path) {
            console.log(index)
            this.defaultActive = index

            if (index == '/') {
                this.$router.push({
                    path: `/`
                })
            } else {
                this.$router.push({
                    path: `/${index}`
                })
            }


            console.log(this.defaultActive)

        },
        handleScroll(e) {
            const distance = window.scrollY
            // console.log(distance)
            if (distance > 400) {
                this.showTop = false
                this.styleHeader = {
                    height: "74px"
                }
            } else {
                this.showTop = true
                this.styleHeader = {
                    height: "120px"
                }
            }
        },
        goTwo(item, index) {
            console.log(item)
            const title = item.subTitle

            let path = ''
            for (let i in this.listMenu) {
                for (let j in this.listMenu[i].child) {
                    if (title == this.listMenu[i].child[j].subTitle && !this.listMenu[i].child[j].id) {
                        // console.log(this.listMenu[i].id)
                        path = this.listMenu[i].id

                        console.log(path)
                        this.defaultActive = path

                        this.$router.push({
                            path: path,
                            query: {
                                tit: item.subTitle
                            }
                        })

                        return

                    } else if (title == this.listMenu[i].child[j].subTitle) {
                        this.defaultActive = this.listMenu[i].id
                    }


                    else {
                        path = item.id




                        this.$router.push({
                            path: path,
                            query: {
                                tit: item.subTitle
                            }

                        })
                    }
                }
            }


        },
        goTO(item) {
            console.log(item)
            if (item.name == '游径') {
                window.open(this.$store.state.homeData.trails)
            } else {
                this.$router.push({
                    path: item.id
                })
            }

        },
        goOther(num) {
            if (num == 1) {
                this.$router.push({
                    path: "/message"
                })
            } else {
                this.$router.push({
                    path: "/question"
                })
            }

        },
        hoverItem(item, index) {
            if (item.name != '首页' && item.name != '方志馆') {
                this.current = index
                this.showHidden = true

                if (index == 1) {
                    this.styleObj = {
                        // transform: `translateX(${Number(index) * 140}px)`
                        transform: `translateX(${Number(index) * 20}%)`

                    }
                } else if (index == 2) {
                    this.styleObj = {
                        // transform: `translateX(${Number(index) * 140}px)`
                        transform: `translateX(${Number(index) * 25}%)`

                    }
                } else if (index == 3) {
                    this.styleObj = {
                        // transform: `translateX(${Number(index) * 140}px)`
                        transform: `translateX(${Number(index) * 20}%)`

                    }
                } else if (index == 4) {
                    this.styleObj = {
                        // transform: `translateX(${Number(index) * 140}px)`
                        transform: `translateX(${Number(index) * 19}%)`

                    }
                } else if (index == 5) {
                    this.styleObj = {
                        // transform: `translateX(${Number(index) * 140}px)`
                        transform: `translateX(${Number(index) * 16}%)`

                    }
                } else if (index == 6) {
                    this.styleObj = {
                        // transform: `translateX(${Number(index) * 140}px)`
                        transform: `translateX(${Number(index) * 15}%)`

                    }
                }

                for (let i in this.listMenu) {
                    if (item.name == this.listMenu[i].title) {
                        // console.log(this.listMenu[i])
                        this.subTitle = this.listMenu[i].child
                    }
                }

            } else {
                this.current = -1
                this.showHidden = false
            }


        },
        hoverLeave() {
            this.current = -1
            this.showHidden = false

        },

        logoClick() {
            this.$router.push({
                name: "index"
            })
        },




        menuSeen() {
            // 屏幕尺寸
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 415) {
                this.seen = true;
            } else {
                this.seen = false;
            }
        },

        openMenu() {
            if (this.flg) {
                this.$refs.drawerRef.open()
                this.menuSrc = require("../../assets/images/phone/x.png")
            } else {
                this.$refs.drawerRef.close()
                this.menuSrc = require("../../assets/images/phone/menu.png")
            }
            this.flg = !this.flg

        },
        changeDraw(state) {
            if (!state) {
                this.menuSrc = require("../../assets/images/phone/menu.png")
                this.flg = true
            }
        },
    }
}
</script>

<style scoped lang="scss">
@import "../../common/mixin.scss";


._topMenu
{
    background: #722013;
}

@media (max-width:1441px)
{
    ._headerAuto
    {
        width: 100% !important;
    }

    ._ww_menu
    {
        width: 90% !important;
    }

}

._webMenu
{
    position: relative;
    height: 76px;
    flex: 1;
}

._webMenu ._ww_menu
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    height: 100%;


    ._li
    {

        font-size: 20px;
        position: relative;
        cursor: pointer;

        height: 76px;
        line-height: 76px;
        margin-left: 60px;
        padding: 0 10px;
        text-align: center;

    }
}

._webMenu ._ww_menu ._liHover
{
    font-weight: bold;
    background-image: url("../../assets/images/Rectangle\ 40.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

._hidden
{
    position: absolute;
    bottom: -60px;
    color: white;
    width: 100%;
    overflow: hidden;
    height: 60px;
    background: rgba(28, 32, 39, 0.85);

    ._hidden_cont
    {
        display: flex;
        align-items: center;
        width: 75%;
        margin: 0 auto;

        p
        {
            line-height: 60px;

            span
            {
                cursor: pointer;
            }

            span:hover
            {
                color: #F9DAA0;
            }

            ._span_active
            {
                color: #F9DAA0;
            }

            img
            {
                width: 6px;
                height: 6px;
                margin: 0 10px;

            }
        }


    }

}

.logo_img
{
    /* float:left; */
    // width: 30%;
    // flex: 1;
    cursor: pointer;
    // margin-right: 20px;
    // width: 404px;

}

#back_to_top
{
    position: fixed;
    bottom: 50px;
    right: 30px;
    cursor: pointer
}

.header-title
{
    font-size: 1em;
}

.company_name
{
    font-weight: normal;
    font-size: 1em;
}

.footer_font
{
    font-size: 1em;
}

#menu_index
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%
}

.header
{

    width: 100%;
    height: 120px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(119, 17, 17, 0.85);

}

.topMenu
{
    background-color: #722013;
}

._headerAuto
{
    margin: auto;
    width: 95%;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-top: 15px;

    ._right
    {
        color: #F9AFA5;

        font-size: 14px;
        display: flex;

        align-items: center;
        font-size: 16px;

        ._xian
        {
            display: inline-block;
            width: 1px;
            height: 16px;
            border: 1px dashed #A95B4F;
            margin: 0 20px;
        }

        span
        {
            cursor: pointer;

        }
    }
}



._boMenu
{
    width: 100%;
    background-color: #8A2415;
    height: 74px;
}

._headerMenu
{
    margin-top: 10px;
    width: 98%;
    margin: 0 auto;
    height: 100%;
}



#header-img
{

    // width: 288px;
    width: 404px;
    margin-left: 20px;
}

.header-logo
{
    // height: 50px;
    // width: 50px;
    // align-self: center;
}

.header-important
{
    float: left;
    margin-left: 6px;
    align-self: center;
}


._sp
{
    color: white;
    font-size: 20px;
    display: inline-block;

    //    padding: 0 30px;
}

.el-menu
{
    background: unset;
}

.el-menu.el-menu--horizontal
{
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-menu--horizontal>.el-menu-item.is-active
{
    border: none;
    // color: blue !important;
    font-weight: bold;
    background-image: url("../../assets/images/Rectangle\ 40.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

.el-menu--horizontal>.el-menu-item
{
    border: none;
    height: auto;
    line-height: normal;
}




.el-menu--horizontal .el-menu-item
{
    // font-size: 20px;
    // padding: 0 40px;
    padding: 0 30px;
    height: 82px;
    line-height: 76px;
    // margin-left: 30px;
    // width: 100px;
    text-align: center;
    margin-top: 10px;


}

// .el-menu--horizontal .el-menu-item:hover
// {
//     font-weight: bold;
//     background-image: url("../../assets/images/Rectangle\ 40.png");
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
// }



::v-deep .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover
{
    // background: transparent !important;
    background-color: transparent !important;
}


::v-deep .el-menu--horizontal>.el-submenu:focus .el-submenu__title,
.el-menu--horizontal>.el-submenu:hover .el-submenu__title
{
    // background: transparent !important;

}

::v-deep .el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title
{
    background-color: #fff !important;
}



.header
{
    @include respondTo('phone')
    {
        height: auto;
        z-index: 6000;
    }
}

._headerMenu
{
    @include respondTo('phone')
    {
        width: 95%;
    }
}

.header ._topMenu
{
    @include respondTo('phone')
    {
        display: none;
    }
}

._boMenu
{
    @include respondTo('phone')
    {
        height: 60px;

    }
}

#header-img
{
    @include respondTo('phone')
    {
        width: 246px;
        margin-left: 0;
    }
}

._div{
    img{
        width: 24px;
        height: 24px;
    }
}
</style>