export const listMenu = [
    {
        title: "关于本馆",
        img: require("@/assets/images/Group 193.png"),
        id: "information",
        child: [
            {
                subTitle: "本馆简介",
            },
            {
                subTitle: "动态",
            },
            {
                subTitle: "领导介绍",
            },
            {
                subTitle: "组织机构",
            },
            {
                subTitle: "理事会",
            },
            {
                subTitle: "监事会",
            },
            {
                subTitle: "馆务公开",
            },
            {
                subTitle: "博物馆之友",
            },
            {
                subTitle: "视频中心",
            },
            {
                subTitle: "社会捐赠",
            },
        ]
    },
    {
        title: "陈列展览",
        img: require("@/assets/images/Group 194.png"),
        id: "visit",
        child: [
            {
                subTitle: "基本陈列",
            },
            {
                subTitle: "专题展览",
            },
            {
                subTitle: "临时展览",
            },
        ]
    },
    {
        title: "公共服务",
        img: require("@/assets/images/Group 152.png"),
        id: "exhibit",
        child: [
            {
                subTitle: "参观服务",
                
            },
            {
                subTitle: "精品文创",
                id:"boutique"
            },
            {
                subTitle: "志愿者之家",
                id:"volHome"
            },

        ]
    },
    {
        title: "宣传教育",
        img: require("@/assets/images/Group 151.png"),

        id: "takeCare",
        child: [
            {
                subTitle: "社教活动",
          
            },

            {
                subTitle: "青少年教育",
                id:'qsnEdu'
            },


        ]
    },
    {
        title: "馆藏文物",
        img: require("@/assets/images/Group 198.png"),

        id: "education",
        child: [
            {
                subTitle: "馆藏珍品",
            },
            {
                subTitle: "藏品数据库",
            },
            {
                subTitle: "藏品目录",
            },
            {
                subTitle: "藏品征集",
            },


        ]
    },
    {
        title: "学术研究",
        img: require("@/assets/images/Group 201.png"),

        id: "study",
        child: [
            {
                subTitle: "学术动态",
            },
            {
                subTitle: "学术委员会",
                id:'studyCom'
            },
            {
                subTitle: "书刊精选",
                id:"studyBook"
            },


        ]
    },
    {
        title: "方志馆",
        img: require("@/assets/images/Group 202.png"),
        id: "trail",
        child:[
            {
                subTitle:"场馆介绍"
            }
        ]

    },
]