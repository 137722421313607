<template>
    <div class="_nr" v-if="seen">
        <div class="_nrAuto">
            <div class="_h4">
                <img src="../../assets/images/Group 1000005904.png" alt="">
            </div>

            <div class="_list">

                <div class="_li" v-for="(item, index) in listMenu" :key="index">
                    <h4>
                        <img :src="item.img" alt="">
                    </h4>
                    <div class="_child" v-if="index != 0">
                        <p v-for="(v, i) in item.child" :key="i" @click="goRoute(item, v)">{{ v.subTitle }}</p>
                    </div>

                    <div class="_child1" v-else>
                        <p v-for="(v, i) in item.child" :key="i" @click="goRoute(item, v)">{{ v.subTitle }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { listMenu } from '../navMenu/list';
export default {
    data() {
        return {
            seen:true,
            listMenu: listMenu
        }
    },
    mounted(){
        this.menuSeen()
        console.log(this.listMenu)
    },
    methods: {
        goRoute(parent, child) {
            console.log(parent)
            console.log(child)

            const title = child.subTitle

            let path = ''
            for (let i in this.listMenu) {
                for (let j in this.listMenu[i].child) {
                    if (title == this.listMenu[i].child[j].subTitle && !this.listMenu[i].child[j].id) {
                        // console.log(this.listMenu[i].id)
                        path = this.listMenu[i].id

                        console.log(path)
                        this.$router.push({
                            path: path,
                            query: {
                                tit: child.subTitle
                            }
                        })
                        return

                    } else {

                        path = child.id

                        this.$router.push({
                            path: path,
                           
                        })
                    }
                }
            }

        },
        
        menuSeen() {
            // 屏幕尺寸
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 415) {
                this.seen = true;
            } else {
                this.seen = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
._nr
{
    width: 100%;
    height: 780px;
    background-image: url("../../assets/images/c_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 80px;
    overflow: hidden;

    ._nrAuto
    {
        width: 85%;
        margin: 0 auto;

        ._h4
        {
            // margin: 0 auto;
            text-align: center;
            margin-top: 151px;
        }

        ._list
        {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            ._li
            {
                width: 200px;

                h4
                {}

                ._child
                {
                    p
                    {
                        background-color: #191C23;
                        width: 200px;
                        height: 40px;
                        line-height: 40px;
                        color: white;
                        text-align: center;
                        opacity: 0.85;
                        margin: 4px 0;

                        cursor: pointer;
                    }

                    p:hover{
                        color: #F9DAA0;
                    }

                }

                ._child1
                {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    p
                    {
                        background-color: #191C23;
                        width: 99px;
                        height: 40px;
                        line-height: 40px;
                        color: white;
                        text-align: center;
                        opacity: 0.85;
                        margin: 4px 0;
                        cursor: pointer;
                    }

                    p:hover{
                        color: #F9DAA0;
                    }
                }
            }
        }
    }
}
</style>