import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';  //用于隐藏某些视口下的元素
import directive from "./derective/vSidein"
import { IMG_URL } from '@/axios/base_api'

import store from "./store/index"
Vue.prototype.$IMG_URL = IMG_URL
Vue.config.productionTip = false;
Vue.use(ElementUI);
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import Pagination from "@/components/Pagination";
import coment from "@/components/coment/coment"
import mainFooter from '@/components/footer/footer'
import fixIcon from '@/components/fixShare/fixShare'
Vue.component('Pagination', Pagination)
Vue.component('coment', coment)

Vue.component('mainFooter', mainFooter)
Vue.component('fixIcon', fixIcon)

import "./assets/font/font.css"
import "./assets/css/reset.css"

import 'swiper/css/swiper.min.css'
import { scrollToSection } from './utils/common'

import { scallHtmlImg } from './utils/scallImg'
Vue.prototype.$scrollToSection = scrollToSection
Vue.prototype.$scallHtmlImg = scallHtmlImg


Vue.prototype.$scrollTop = function () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // 平滑滚动
  })
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  // mounted() {

  //   setTimeout(() => {
  //     document.dispatchEvent(new Event('render-event'))
  //   }, 5000)
  // }
}).$mount("#app");
