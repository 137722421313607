import axios from "@/axios/until";

/**
 * 查询首页数据
 */
export function getHomeData(data) {
  return axios({
    url: '/home',
    method: 'post',
    data: data
  })
}

/**
 * 查询列表
 */

export function getDataById(data) {
  return axios({
    url: '/list',
    method: 'post',
    data: data
  })
}

/**
 * 详情
 */

export function getDetail(data) {
  return axios({
    url: '/detail',
    method: 'post',
    data: data
  })
}

/**
 * 留言
 */

export function postMsg(data) {
  return axios({
    url: '/message',
    method: 'post',
    data: data
  })
}

/**
 * 问卷调查列表
 */
export function research(data) {
  return axios({
    url: '/research',
    method: 'post',
    data: data
  })
}

/**
 * 提交调查问卷
 */
export function submitQuestion(data) {
  return axios({
    url: '/submit',
    method: 'post',
    data: data
  })
}

/**
 * 视频播放点击量
 */
export function addHits(data) {
  return axios({
    url: '/hits',
    method: 'post',
    data: data
  })
}

/**
 * pdf pdfFileStreams
 */
export function pdfFileStreams(data) {
  return axios({
    url: '/pdfFileStreams',
    method: 'post',
    data: data,
  })
}

/**
 * 点击量
 */


export function hits(data) {
  return axios({
    url: '/hits',
    method: 'post',
    data: data,
  })
}






































