<template>
    <el-drawer title="" :visible.sync="drawer" :direction="direction" size="100%" :with-header="false"
        :append-to-body="true" :modal-append-to-body="false" style="margin-top: 60px;">
        <div class="_drawer">


            <div class="_content">

                <div class="_cont">
                    <div class="_li" @click='go1'>
                        <h4>首页</h4>
                    </div>
                    <div class="_li" v-for="(item, index) in listMenu" :key="index" @click.stop="goPara(item)">
                        <h4>{{ item.title }}</h4>
                        <div class="_child" v-if="item.child">
                            <p v-for="(v, i) in item.child" :key="i" @click.stop="go(item, v)">{{ v.subTitle }}</p>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import { listMenu } from './list';

const a = JSON.stringify(listMenu)
const b = JSON.parse(a)

export default {
    data() {
        return {
            drawer: false,
            direction: 'ttb',
            listMenu: b,
        }
    },
    created() {
        const rout1 = {
            title: "意见反馈",
            id: "message",
        }
        const rout2 = {
            title: "问卷调查",
            id: "question",
        }

        this.listMenu.push(rout1)
        this.listMenu.push(rout2)
    },
    methods: {
        open() {
            this.drawer = true
        },
        close() {
            this.drawer = false
        },

        goPara(item, v) {
            console.log(item)
            this.drawer = false
            this.$emit("changeDraw", this.drawer)
            this.$router.push({
                path: `/${item.id}`,

            })
        },
        go(item, v) {
            this.drawer = false
            this.$emit("changeDraw", this.drawer)
            // console.log(item)
            console.log(v)

            if (v.id) {
                this.$router.push({
                    path: `/${v.id}`,

                })
            } else {
                this.$router.push({
                    path: `/${item.id}`,
                    query: {
                        tit: v.subTitle
                    }

                })
            }





        },
        go1() {
            this.drawer = false
            this.$emit("changeDraw", this.drawer)
            this.$router.push({
                path: `/`,

            })
        }
    }
}
</script>

<style lang="scss" scoped>
._drawer
{
    width: 100%;
    // height: 100%;
    background-color: #722013;

    ._content
    {

        overflow: hidden;

        ._cont
        {

            margin-top: 20px;

            ._li
            {
                margin-bottom: 15px;

                h4
                {
                    color: #EED798;
                    font-size: 20px;
                    margin-left: 20px;
                }

                ._child
                {
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    background-color: #8C3123;
                    padding: 10px 20px;

                    p
                    {

                        color: #E1CF9E;
                        font-size: 16px;
                        cursor: pointer;
                        margin-right: 4.5%;
                        line-height: 30px;
                    }

                    p:nth-child(5)
                    {
                        margin-right: 0;
                    }
                }
            }


        }
    }
}
</style>
