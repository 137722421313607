<template>
  <div class="_wrap_footer">
    <div class="footer_auto">
      <div class="_fwl">
        <p>网站访问量</p>
        <div class="_numList">
          <span class="_num" v-for="(item, index) in visits" :key="index">{{
            item
          }}</span>
        </div>
      </div>

      <div class="_auth">
        <p>
          <span>版权所有：{{ footerData.web_data.copyright }}</span>
          <a :href="aHref" target="_blank" class="_a" rel="noreferrer">
            <img src="../../assets/images/ga.png" alt="" />
            <i>豫公网安备 {{ pluceNum }}</i>
          </a>
          <span class="_ba" @click="goBa">{{
            footerData.web_data.record_number
          }}</span>
        </p>
      </div>

      <div class="info">
        <div class="_ide" v-if="!seen">
          <span id="_ideConac"
            ><a
              href="https://bszs.conac.cn/sitename?method=show&id=0EA65FA27A567253E06310291AACBDF3"
              target="_blank"
              ><img
                id="imgConac"
                vspace="0"
                hspace="0"
                border="0"
                src="https://dcs.conac.cn/image/blue.png"
                data-bd-imgshare-binded="1" /></a
          ></span>
        </div>

        <div class="_div">
          <div class="_right">
            <img src="../../assets/images/image 15.png" alt="" />
            <span>{{ footerData.web_data.address }}</span>
          </div>
          <div class="_left">
            <img src="../../assets/images/image 16.png" alt="" />
            <span>{{ footerData.web_data.botton_tel }}</span>
          </div>
        </div>
      </div>

      <div class="_ide" v-if="seen">
        <span id="_ideConac"
          ><a
            href="https://bszs.conac.cn/sitename?method=show&id=0EA65FA27A567253E06310291AACBDF3"
            target="_blank"
            ><img
              id="imgConac"
              vspace="0"
              hspace="0"
              border="0"
              src="https://dcs.conac.cn/image/blue.png"
              data-bd-imgshare-binded="1" /></a
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seen: true,
      footerData: {},
      visits: "",
      pluceNum: "41030402000223", //英文域名
      aHref: "",
    };
  },
  created() {
    // this.getweb_data()
    this.footerData = this.$store.state.homeData;
    // 网站访问量

    let visits = this.footerData.visits + "";
    // let visits  = '45123212'
    this.visits = visits.split("");
  },
  mounted() {
    this.menuSeen();
    let url = location.origin;
    if (url.indexOf("zglyzdsdjng") != -1){
        // 英文域名
        this.pluceNum = 41030402000223
        

    }else{
        // 中文域名
        this.pluceNum = 41030402000219
    }
    this.aHref = `https://beian.mps.gov.cn/#/query/webSearch?code=${this.pluceNum}`;

    setTimeout(() => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://dcs.conac.cn/js/17/253/0000/61072721/CA172530000610727210001.js";

      const ele = document.querySelector("._ide");
      ele.appendChild(script);
    }, 500);
  },
  methods: {
    goBa() {
      window.open("https://beian.miit.gov.cn/");
    },
    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._wrap_footer {
  width: 100%;
  // height: 177px;
  background-color: #191c23;
  color: #999999;
  text-align: center;
  overflow: hidden;

  .footer_auto {
    margin-top: 48px;
  }
}

._fwl {
  display: flex;
  // width: 22%;
  justify-content: center;

  // margin: 0 auto;

  p {
    font-size: 17px;
    margin-right: 14px;
  }

  ._numList {
    ._num {
      display: inline-block;
      text-align: center;
      width: 24px;
      height: 24px;
      line-height: 24px;
      background: #3d4760;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 0 5px;
    }
  }
}

._auth {
  margin: 30px 0 16px;

  ._a {
    margin-left: 20px;
    img {
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
  ._ba {
    margin-left: 20px;
    cursor: pointer;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
margin-bottom: 20px;
  ._div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._left {
    display: flex;
    align-items: center;

    img {
      vertical-align: middle;
      margin-right: 6px;
    }
  }

  ._right {
    margin-left: 30px;
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
      margin-right: 10px;
    }
  }
}

._wrap_footer {
  @include respondTo("phone") {
    padding-bottom: 20px;
  }
}

.footer_auto {
  @include respondTo("phone") {
    width: 90%;
    margin: 0 auto;
    margin-top: 40px !important;

    ._fwl {
      justify-content: flex-start;
    }

    ._auth {
      @include respondTo("phone") {
        text-align: left;
        margin: 18px 0 18px;

        span {
          display: block;
          margin-top: 5px;
        }
        ._a {
          margin-left: 0;
        }
        ._ba {
          margin-left: 0;
        }
      }
    }

    .info {
      justify-content: space-between;

      ._ide {
        #_ideConac {
          a {
            #imgConac {
              width: 20px;
            }
          }
        }
      }

      ._div {
        display: block;

        ._left {
          margin-top: 10px;
        }

        ._right {
          margin-left: 0;
        }
      }
    }
  }
}
</style>