import Vue from "vue"
import Vuex from 'vuex'
Vue.use(Vuex);

import createPersistedstate from 'vuex-persistedstate'
import { getHomeData } from "../api/getData";


const store = new Vuex.Store({
    state: {
        homeData: {},

    },
    mutations: {
        // 保存首页数据
        SAVE_DATA(state, homeData) {
            state.homeData = homeData;
        },
      

    },
    actions: {
        GETHOMEDATA({ commit }) {
            return new Promise((resolve, reject) => {
                getHomeData().then(res => {
                    // console.log(res)
                    commit("SAVE_DATA", res.data)
                    resolve(res)
                })
            })

        },
       
    },
    plugins: [
        createPersistedstate({
            storage: window.sessionStorage,
            // key: 'homeData',// 存数据的key名   自定义的  要有语义化
            // paths: ['loginModule'] // 要把那些模块加入缓存
            reducer(val){
                return{
                   homeData:val.homeData,
                }
            }
        })
    ]

})

export default store
